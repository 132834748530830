import { PageInfoProps, PublicationFormattedProps, PublicationProps, SEOProps } from './pages'
import { GatsbyImageBasicProps } from './images'
import { SocialProps, VideoProps } from './items'
import { ResourceCoverProps } from './resources'

/*
 * Author
 */
export interface AuthorProps {
  id: string
  name: string
  pageInfo: PageInfoProps
  role: string
  profilePicture: GatsbyImageBasicProps
  socials: SocialProps[]
}

/*
 * Category
 */
export interface CategoryProps {
  id: string
  name: string
  pageInfo: PageInfoProps
}

/*
 * Article
 */

//
// TODO: ArticleObject to be completed with content
// ArticleObject is not exported, create new type picking what you need
//
interface ArticleObject {
  id: string
  title: string
  slug: string
  locale: string
  authors: AuthorProps[]
  categories: CategoryProps[]
  excerpt: string
  moreLabel: string
  thumbnail: GatsbyImageBasicProps
  cover: GatsbyImageBasicProps & { url?: string }
  seo: SEOProps
  metaFormatted: PublicationFormattedProps
  meta: PublicationProps
  showNewsLetterSection: boolean
  sections: (
    | BlogSectionText
    | BlogSectionImage
    | BlogSectionQuote
    | BlogSectionFeaturedResource
    | BlogSectionVideo
    | BlogSectionPodcast
  )[]
}

export type ArticlePreviewProps = Pick<
  ArticleObject,
  'id' | 'slug' | 'title' | 'excerpt' | 'thumbnail' | 'cover' | 'moreLabel' | 'metaFormatted'
>

export type ArticlePreviewWithAuthorProps = Pick<
  ArticleObject,
  'id' | 'slug' | 'title' | 'excerpt' | 'thumbnail' | 'cover' | 'moreLabel' | 'metaFormatted' | 'authors'
>

export type ArticlePreviewWithAuthorAndCategoriesProps = Pick<
  ArticleObject,
  'id' | 'slug' | 'title' | 'excerpt' | 'thumbnail' | 'cover' | 'moreLabel' | 'metaFormatted' | 'authors' | 'categories'
>

export type Article = ArticleObject

/*
 * Blog landing
 */
export interface BlogLandingProps {
  headlinerArticle: ArticlePreviewWithAuthorAndCategoriesProps
  headlinerBackgroundImage: GatsbyImageBasicProps
  headlinerBackgroundColor: {
    hex: string
  }
  featuredArticles: ArticlePreviewWithAuthorAndCategoriesProps[]
  resourcesHeadline: string
  resources: ResourceCoverProps[]
  showNewsLetterSection: boolean
  featuredCategoriesTitle: string
  allArticlesLabel: string
  articlesListLabel: string
  paginationPreviousLabel: string
  paginationNextLabel: string
}

/*
 * Blog section
 */
export interface BlogSectionProps {
  id: string
  model: {
    apiKey: string
  }
}

/*
 * Blog section basic
 */
export interface BlogSectionText extends BlogSectionProps {
  content: string
}
/*
 * Blog section image
 */
export interface BlogSectionImage extends BlogSectionProps {
  image: GatsbyImageBasicProps & { url: string }
}

/*
 * Blog section quote
 */
export interface BlogSectionQuote extends BlogSectionProps {
  quote: string
  author: string
}

/*
 * Blog section featured resource
 */
export interface BlogSectionFeaturedResource extends BlogSectionProps {
  resource: ResourceCoverProps
  buttonLabel: string
  backgroundImage: GatsbyImageBasicProps
  backgroundColor: {
    hex: string
  }
}

/*
 * Blog section embed video
 */
export interface BlogSectionVideo extends BlogSectionProps {
  video: VideoProps
  backgroundColor: {
    hex: string
  }
}

/*
 * Blog section embed podcast
 */
export interface BlogSectionPodcast extends BlogSectionProps {
  iframeUrl: string
}

export function isBlogSectionImage(
  section:
    | BlogSectionImage
    | BlogSectionText
    | BlogSectionQuote
    | BlogSectionFeaturedResource
    | BlogSectionVideo
    | BlogSectionPodcast
    | null
): section is BlogSectionImage {
  return !!(section as BlogSectionImage)?.image
}

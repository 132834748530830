import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Types
import { BlogSectionImage, isBlogSectionImage } from '../../types/blog'
import { CustomerStory as CustomerStoryProps, CustomerStoryPreview } from '../../types/customerStories'
// Components
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { Layout, Seo } from '../../components/commons'
import { Box, Button, Heading, Image, Text } from '../../atoms'
import { SectionBlog } from '../../components/blog'
import { CustomerStoryHero } from '../../components/customerStories'
import { StoryCard } from '../../components/customerStories/commons'

interface DataQuery {
  story: CustomerStoryProps
  utils: {
    customerIndustryLabel: string
    customerHeadquartersLabel: string
    customerChallengeLabel: string
    customerSolutionLabel: string
    moreStoriesLabel: string
  }
  storiesList: { nodes: CustomerStoryPreview[] }
}

const CustomerStory: React.FC<PageProps<DataQuery>> = ({ data }) => {
  const {
    story,
    utils,
    storiesList: { nodes: stories },
  } = data

  // Structured Data
  const blogImageSections: string[] = story.sections
    .filter<BlogSectionImage>(isBlogSectionImage)
    .map((s) => s.image.url)

  const CustomerStorySDataSchema = {
    pageType: 'NewsArticle',
    headline: story.title,
    image: blogImageSections,
  }

  return (
    <Layout>
      <Seo
        slug={story.slug}
        publication={story.meta}
        lang={story.locale}
        content={
          story.seo || {
            title: story.title,
            description: story.excerpt,
            twitterCard: null,
            image: null,
          }
        }
        SDSchema={CustomerStorySDataSchema}
      />
      {/* Page wrapper */}
      <Box pt={[100, 120]}>
        {/* Story */}
        <Box position={'relative'}>
          <Box as={'article'}>
            <Grid>
              <Row>
                <Col xs={12} md={4} lg={4}>
                  {/*  Customer section here */}
                  {story.customerLogo && (
                    <Image alt={story.customerLogo.alt || ''} image={story.customerLogo.gatsbyImageData} />
                  )}
                  <Box
                    mt={[7, 8, 10]}
                    mb={7}
                    position={['relative', 'sticky']}
                    top={[0, 'calc(120px + var(--banner-height))']}
                  >
                    <Row>
                      <Col xs={12} md={6}>
                        <Box>
                          <Text fontWeight={'semi'} fontSize={2}>
                            {utils.customerIndustryLabel}
                          </Text>
                          {!!story.customerIndustry && <Text fontSize={2}>{story.customerIndustry}</Text>}
                        </Box>
                      </Col>
                      <Col xs={12} md={6}>
                        <Box mt={[4, 5, 0]}>
                          <Text fontWeight={'semi'} fontSize={2}>
                            {utils.customerHeadquartersLabel}
                          </Text>
                          {!!story.customerHeadquarters && <Text fontSize={2}>{story.customerHeadquarters}</Text>}
                        </Box>
                      </Col>
                    </Row>
                    <Box mt={[4, 5, 6]}>
                      <Text fontWeight={'semi'} fontSize={2}>
                        {utils.customerChallengeLabel}
                      </Text>
                      {!!story.customerChallenge && <Text fontSize={2}>{story.customerChallenge}</Text>}
                    </Box>
                    <Box mt={[4, 5, 6]}>
                      <Text fontWeight={'semi'} fontSize={2}>
                        {utils.customerSolutionLabel}
                      </Text>
                      {!!story.customerSolution && <Text fontSize={2}>{story.customerSolution}</Text>}
                    </Box>
                    {story.customerButtonLink && (
                      <Box mt={[4, 5, 6]}>
                        <Button {...story.customerButtonLink} fluid />
                      </Box>
                    )}
                  </Box>
                </Col>
                <Col xs={12} md={8} lg={8}>
                  <Box pl={[0, 0, 6]}>
                    <CustomerStoryHero
                      title={story.title}
                      author={story.author}
                      categories={story.categories}
                      metaFormatted={story.metaFormatted}
                    />
                    {story.sections.map(
                      (s) => s?.model && <SectionBlog key={s.id} sectionApiKey={s.model.apiKey} data={s} />
                    )}
                  </Box>
                </Col>
              </Row>
              {stories.length > 0 && (
                <Box mt={[80, 100, 120]}>
                  <Box mb={8}>
                    <Heading as={'h3'}>{utils.moreStoriesLabel}</Heading>
                  </Box>
                  <Row>
                    {stories.map((story: CustomerStoryPreview) => (
                      <Col key={story.id} xs={12} sm={6} md={4}>
                        <Box mb={[7, 8, 10]}>
                          <StoryCard data={story} />
                        </Box>
                      </Col>
                    ))}
                  </Row>
                </Box>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query CustomerStoryQuery($slug: String, $categories: [String!]!) {
    story: datoCmsCustomerStory(slug: { eq: $slug }) {
      ...customerStoryFragment
    }
    utils: datoCmsCustomerStoriesLanding {
      customerIndustryLabel
      customerHeadquartersLabel
      customerChallengeLabel
      customerSolutionLabel
      moreStoriesLabel
    }
    storiesList: allDatoCmsCustomerStory(
      limit: 3
      sort: { order: DESC, fields: meta___firstPublishedAt }
      filter: { categories: { elemMatch: { id: { in: $categories } } } }
    ) {
      nodes {
        ...customerStoryPreviewWithCategoriesFragment
      }
    }
  }
`

export default CustomerStory
